import React, { Component } from "react";
import '../styles/contact.css'
import { Form, Input, TextArea, Button, Embed, Divider } from 'semantic-ui-react'
 
class Contact extends Component {

  state = { first_name: '', last_name: '', subject: '', message: '' };

  handleChange = (e, { name, value }) => this.setState({ [name]: value })

  submit() {
    window.open("mailto:hello@lbphotos.co.za?subject="
    + encodeURIComponent(this.state.subject)
    + "&body=" + encodeURIComponent("Client name: " + this.state.first_name + " " + this.state.last_name + "\n" + this.state.message), '_blank');
    this.setState({ first_name: '', last_name: '', subject: '', message: '' });
  }
  
  render() {
    const { first_name, last_name, subject, message } = this.state
    return (
      <div id="contact-body">
        <section id="contact-container">
          <div id="contact-description" className="column">
            <h1 className="title">CONTACT</h1>
            <p className="text-body">
              Please fill out the following form to make an enquiry, be sure to include any important details and I will get back to you as soon as possible!
              <br></br>
              <br></br>
              Cellphone: 
            </p>
            <a href="tel:27718542399" >+27 71 854 2399</a>
          </div>
          <div id="contact-image" className="column">
          <Form size='large' key='large'>
            <Form.Group widths='equal'>
              <Form.Field>
                <Input 
                  fluid placeholder='First name'
                  name='first_name'
                  value={first_name}
                  onChange={this.handleChange}
                />
              </Form.Field>
              <Form.Field>
                <Input 
                  fluid placeholder='Last name' 
                  name='last_name'
                  value={last_name}
                  onChange={this.handleChange}
                />
              </Form.Field>
            </Form.Group>
            <Form.Field>
              <Input 
                fluid placeholder='Subject'
                name='subject'
                value={subject}
                onChange={this.handleChange}
              />
            </Form.Field>
            <Form.Field
              fluid placeholder='Message'
              control={TextArea}
              name='message'
              value={message}
              onChange={this.handleChange}
            />
          </Form>
          <div className="divider"></div>
          <Form.Button content='SUBMIT' size='large' basic color='grey' onClick={() => this.submit()}/>
          </div>
        </section>
      </div>
    );
  }
}
 
export default Contact;