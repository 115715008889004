import React, { Component } from "react";
import firebase from '../firebase/Firebase'
import '../styles/albums.css'

import {
    Route,
    NavLink,
    HashRouter
  } from "react-router-dom";
 
class Albums extends Component {
  constructor(props) {
    super(props);
    this.ref = firebase.firestore().collection('albums');
    this.unsubscribe = null;
    this.state = {
      albums: []
    };
  }


  onCollectionUpdate = (querySnapshot) => {
    const albums = [];
    querySnapshot.forEach((doc) => {
      const { description, photos, title } = doc.data();
      albums.push({
        key: doc.id,
        doc, // DocumentSnapshot
        title,
        description,
        photos
      });
    });

    this.setState({
      albums
   });
  }

  componentDidMount() {
    this.unsubscribe = this.ref.onSnapshot(this.onCollectionUpdate);
  }

  getTitle(title) {
    return title.prototype.toUpperCase();
  }

  render() {
    return (
      <div id="albums-body">
        <section id="albums-container">
          {
            this.state.albums.map(album =>
              <NavLink to={{pathname: '/portfolio/gallery', state: {photos: album.photos, title: album.title, description: album.description}}} style={{ textDecoration: 'none' }}>
                <div class="album-block">
                  <div class="image-wrapper">
                    <img src={album.photos[0]}></img>
                  </div>
                  <div class="album-title">{album.title.toUpperCase()}</div>
                </div> 
              </NavLink>
            )
          }
        </section>
      </div>
    );
  }
}
 
export default Albums;