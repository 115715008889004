import React, { Component, Fragment, useState} from "react";
import "../styles/photo_gallery.css";
import Masonry from 'react-masonry-css'
import { ReactBnbGallery } from 'react-bnb-gallery';
 
class PhotoGallery extends Component {

  constructor(props) {
    super(props);
    if(this.props.location.state != null) {
      localStorage.setItem('photos', JSON.stringify(this.props.location.state.photos));
      localStorage.setItem('title', this.props.location.state.title);
      localStorage.setItem('description', this.props.location.state.description);
    }
    this.state = {
      selectedIndex: 0,
      photos: JSON.parse(localStorage.getItem('photos')) || this.props.location.state.photos,
      title: localStorage.getItem('title') || this.props.location.state.title,
      description: localStorage.getItem('description') || this.props.location.state.description,
      modalVisible: false,
      galleryOpened: false
    };
    this.toggleGallery = this.toggleGallery.bind(this);
  }

  toggleGallery() {
    this.setState(prevState => ({
      galleryOpened: !prevState.galleryOpened
    }));
  }

  render() {

    const breakpointColumnsObj = {
      default: 5,
      1500: 4,
      1000: 3,
      800: 2,
      600: 1
    };

    return(
      <Fragment>
        <div id="description-block">
          <div id="center-div">
            <h1 className="title">{this.state.title.toUpperCase()}</h1>
            <p className="text-body">{this.state.description}</p>
          </div>
        </div>
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          {this.state.photos.map((photo, i) => 
            <div onClick={this.toggleGallery}>
              <img src={photo} alt={photo}></img>
            </div>
          )}
        </Masonry>
      </Fragment>
    );
  }
}
 
export default PhotoGallery;