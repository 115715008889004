import * as firebase from 'firebase';
import firestore from 'firebase/firestore'

const settings = {timestampsInSnapshots: true};

const config = {
    apiKey: "AIzaSyChv0LW8tRUZcAIOSk6yOZdr56Um6-ATBY",
    authDomain: "lee-burrell-photography.firebaseapp.com",
    databaseURL: "https://lee-burrell-photography.firebaseio.com",
    projectId: "lee-burrell-photography",
    storageBucket: "lee-burrell-photography.appspot.com",
    messagingSenderId: "1040825268857",
    appId: "1:1040825268857:web:1855becb5454ee62d172a8",
    measurementId: "G-CQLFX4VDQH"
};
firebase.initializeApp(config);
firebase.analytics();
firebase.firestore().settings(settings);

export default firebase;