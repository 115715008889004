import React, { Component } from "react";
import '../styles/about.css'
 
class About extends Component {
  render() {
    return (
      <div id="about-body">
        <section id="about-container">
          <div id="about-image" className="column">
            <img src={"./assets/lee_about.jpg"} />
          </div>
          <div id="about-description" className="column">
            <h1 className="title">ABOUT</h1>
            <p className="text-body">Hi! I’m Lee- a lover of sunshine and capturing life’s happiest moments. I grew up with photography in my family, so my passion was kindled from a young age and nurtured by my mother. As a creative, I try shoot whatever comes my way. I have done, amongst others, preschool first day and graduations, matric dances, events, weddings and portfolio shoots and loved each one differently. I realise everything is somewhat transient, and so we must do everything we can to capture it as it is today so that we can treasure as many memories and lived experiences forever. Let me be the gateway to you reliving your fondest memories.<br></br><br></br>I am based primarily in Cape Town as well as Johannesburg at certain times throughout the year.</p>
          </div>
        </section>
      </div>
    );
  }
}
 
export default About;