import React, { Component } from "react";
import {
    Route,
    NavLink,
    HashRouter,
    Redirect
  } from "react-router-dom";

import Albums from "./views/Albums";
import PhotoGallery from "./views/PhotoGallery"
import About from "./views/About";
import Contact from "./views/Contact";
import "./styles/main.css";
 
class Main extends Component {

  constructor(props) {
		super(props)
		this.state = {
      showSideNav: false
    };
	}

  openNav() {
    this.setState(
      {showSideNav: true}
    );
  }
    
  closeNav() {
    this.setState(
      {showSideNav: false}
    );
  }

  render() {
    return (
        <HashRouter>
            <div id="mySidenav" class="sidenav" style={{width: this.state.showSideNav ? '100%' : '0'}}>
                <a href="javascript:void(0)" class="closebtn" onClick={() => this.closeNav()}>&times;</a>
                <NavLink to="/portfolio/albums" activeClassName="chosen" onClick={() => this.closeNav()}>WORK</NavLink>
                <NavLink to="/about" activeClassName="chosen" onClick={() => this.closeNav()}>ABOUT</NavLink>
                <NavLink to="/contact" activeClassName="chosen" onClick={() => this.closeNav()}>CONTACT</NavLink>
            </div>
            <div>
                <div className="navbar">
                    <div className="navbar-list">
                        <NavLink to="/portfolio/albums" activeClassName="chosen" >WORK</NavLink>
                        <NavLink to="/about" activeClassName="chosen">ABOUT</NavLink>
                        <NavLink to="/contact" activeClassName="chosen">CONTACT</NavLink>
                    </div>
                    <div className="navbar-right">
                      <div><p id="navbar-logo" class="icon">LB PHOTOS</p></div>
                      <div><p id="navbar-burger" onClick={() => this.openNav()}>&#9776;</p></div>
                    </div>
                </div>
                <div className="content">
                    <Redirect from="/" to="/portfolio/albums" />
                    <Route exact path="/portfolio/albums" component={Albums}/>
                    <Route path="/portfolio/gallery" component={PhotoGallery}/>
                    <Route path="/about" component={About}/>
                    <Route path="/contact" component={Contact}/>
                </div>
            </div>
        </HashRouter>
    );
  }
}
 
export default Main;